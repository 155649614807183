import { useEffect, useContext } from "react";
import { useLazyQuery, useMutation, gql } from "@apollo/client";
import { useIntl } from "react-intl";

import { handleError } from "../utils";
import graphQLContext from "../context/graphQLContext";
import { NotificationContext } from "../../../providers/NotificationsProvider";

const GET_TENANTS_BY_ID = gql`
  query tenantById($id: ID!) {
    tenantById(id: $id) {
      id
      name
      supportEmails
      dashboard {
        widgets
      }
      license {
        id
        startDate
        endDate
        users {
          current
          total
        }
        sites {
          current
          total
        }
        devices {
          current
          total
        }
        tenant {
          id
          name
          status
          createdOn
          updatedOn
        }
        status
      }
      alarms {
        total
        byDeviceType
        bySeverity
      }
      devices {
        total
        online
      }
      level
      status
      createdOn
      updatedOn
      managed
    }
  }
`;

const ADD_TENANT = gql`
  mutation addTenant($input: AddTenantInput!) {
    addTenant(input: $input) {
      tenant {
        id
        name
        createdOn
        updatedOn
        status
      }
    }
  }
`;

const UPDATE_TENANT = gql`
  mutation updateTenant($input: UpdateTenantInput!) {
    updateTenant(input: $input) {
      tenant {
        id
        name
        license {
          id
          tenant {
            id
            name
            status
            createdOn
            updatedOn
          }
          startDate
          endDate
          users {
            current
            total
          }
          sites {
            current
            total
          }
          devices {
            current
            total
          }
          status
        }
        dashboard {
          widgets
        }
        status
      }
    }
  }
`;

const UPDATE_SUPPORT_EMAILS = gql`
  mutation UpdateSupportEmail($input: UpdateSupportEmailInput!) {
    updateSupportEmail(input: $input) {
      supportEmails {
        id
        emails
        createdOn
        createdBy {
          firstName
          lastName
        }
        updatedOn
        updatedBy {
          firstName
          lastName
        }
        status
      }
    }
  }
`;

const useTenant = () => {
  const { uri }: any = useContext(graphQLContext);
  const intl = useIntl();
  const { openNotification }: any = useContext(NotificationContext);

  const [
    fetchTenantById,
    {
      loading: isfetchTenantByIdLoading,
      error: fetchTenantByIdError,
      data: fetchTenantByIdData,
    },
  ] = useLazyQuery(GET_TENANTS_BY_ID, {
    fetchPolicy: "no-cache",
  });

  const [
    addTenant,
    {
      loading: isAddTenantLoading,
      error: addTenantError,
      data: addTenantResponseData,
      reset: resetAddTenantData,
    },
  ] = useMutation(ADD_TENANT);

  const [
    updateTenant,
    {
      loading: isUpdateTenantLoading,
      error: updateTenantError,
      data: updateTenantResponseData,
      reset: resetUpdateTenantData,
    },
  ] = useMutation(UPDATE_TENANT);

  const [
    updateSupportEmails,
    {
      loading: updateSupportEmailsLoading,
      error: updateSupportEmailsError,
      data: updateSupportEmailsResponse,
      reset: resetUpdateSupportEmails,
    },
  ] = useMutation(UPDATE_SUPPORT_EMAILS, {
    refetchQueries: [GET_TENANTS_BY_ID],
  });

  useEffect(() => {
    if (
      fetchTenantByIdError ||
      addTenantError ||
      updateTenantError ||
      updateSupportEmailsError
    ) {
      const error = addTenantError || updateTenantError || fetchTenantByIdError;
      handleError(error, uri, openNotification, intl);
    }
  }, [
    fetchTenantByIdError,
    addTenantError,
    updateTenantError,
    updateSupportEmailsError,
    openNotification,
    intl,
    uri,
  ]);

  return {
    fetchTenantById,
    isfetchTenantByIdLoading,
    fetchTenantByIdError,
    fetchTenantByIdData,
    addTenant,
    isAddTenantLoading,
    addTenantError,
    addTenantResponseData,
    resetAddTenantData,
    updateTenant,
    isUpdateTenantLoading,
    updateTenantError,
    updateTenantResponseData,
    resetUpdateTenantData,
    updateSupportEmails,
    updateSupportEmailsLoading,
    updateSupportEmailsResponse,
    resetUpdateSupportEmails,
  };
};

export default useTenant;
