const messages = {
  // Languages
  "en-GB": "English",
  "zh-CN": "Chinese (中文)",
  "fr-FR": "French (française)",
  "de-CH": "German (Deutsch)",

  // menu
  menu_home: "Home",
  menu_site: "Locations",
  "menu_device-monitoring": "Devices", // Remove this once New device MFE is ready
  menu_device: "Devices",
  menu_alarm: "Alarms",
  menu_user: "Users",
  menu_profile: "Profile",
  menu_tenant: "Tenants",
  menu_license: "Licenses",
  menu_telemetry: "Telemetries",
  menu_attachments: "Attachments",
  menu_gateway: "Gateway",
  menu_analytics: "Analytics",

  select_tenant: "Tenant",
};

export default messages;
