import { useContext } from "react";
import { DarkModeContext } from "../../providers/DarkModeProvider";
import { Breadcrumb, ConfigProvider, Flex } from "antd";
import { SingleArrowRightIcon } from "../../icons";

const BasicBreadcrumbs = (props) => {
  const { items, style } = props;
  const { theme } = useContext<any>(DarkModeContext);

  return (
    <ConfigProvider theme={theme}>
      <Breadcrumb
        separator={
          <Flex
            style={{
              width: "40px",
              justifyContent: "center",
              lineHeight: "16px",
            }}
          >
            <SingleArrowRightIcon />
          </Flex>
        }
        style={{
          fontFamily: "Poppins",
          fontWeight: 600,
          padding: "4px",
          lineHeight: "16px",
          ...style,
        }}
        items={items}
      />
    </ConfigProvider>
  );
};

export default BasicBreadcrumbs;
