import { useContext } from "react";
import { ConfigProvider, Space, type UploadFile } from "antd";
import { DarkModeContext } from "../../providers/DarkModeProvider";
import { Caption12Tertiary, Body16RegularPrimary } from "../../commonStyles";
import { StyledDrager, TextButton } from "./styled";
import { UploadFileIcon, CloseIcon, UploadIcon } from "../../icons";
import { useIntl } from "react-intl";
export interface DraggerType {
  allowedExtensions: string[];
  filelist: UploadFile[];
  setFilelist: React.Dispatch<React.SetStateAction<UploadFile[]>>;
  onChange: (event) => void;
}

const Dragger = ({
  allowedExtensions = [],
  filelist = [],
  setFilelist = () => {},
  onChange = () => {},
}: DraggerType) => {
  const intl = useIntl();
  const { theme } = useContext<any>(DarkModeContext);
  const { token } = theme;

  return (
    <ConfigProvider theme={theme}>
      <StyledDrager
        listType="picture"
        fileList={filelist}
        accept={allowedExtensions.join(",")}
        beforeUpload={(file) => {
          setFilelist([file]);
          return false;
        }}
        onRemove={() => setFilelist([])}
        iconRender={() => (
          <UploadFileIcon
            style={{
              height: 24,
              width: 24,
              color: token.datwylerIcon.iconInvert,
            }}
          />
        )}
        showUploadList={{
          extra: ({ size = 0 }) => (
            <Caption12Tertiary style={{ display: "block" }}>
              {(size / 1024 / 1024).toFixed(2)}MB
            </Caption12Tertiary>
          ),
          removeIcon: (
            <CloseIcon
              style={{
                height: 24,
                width: 24,
                color: token.datwylerIcon.iconPrimary,
                marginTop: 8,
              }}
            />
          ),
        }}
        onChange={onChange}
      >
        <Space direction="vertical" style={{ display: "flex" }}>
          <div>
            <UploadIcon
              style={{
                height: 24,
                width: 24,
                color: token.datwylerIcon.iconBrand,
              }}
            />
          </div>
          <div>
            <div>
              <Body16RegularPrimary>
                {intl.formatMessage({ id: "drag_drop_label" })}&nbsp;
                <TextButton>
                  {intl.formatMessage({ id: "choose_file" })}
                </TextButton>
              </Body16RegularPrimary>
            </div>
            <div>
              <Caption12Tertiary>
                {intl.formatMessage({ id: "maximun_file_size" })}
              </Caption12Tertiary>
            </div>
          </div>
        </Space>
      </StyledDrager>
    </ConfigProvider>
  );
};

export default Dragger;
