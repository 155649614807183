const messages = {
  // Device Monitoring
  select_site: "选择站点",
  select_device_type: "选择设备类型",
  type: "类型",
  device_id: "设备ID",
  copy_device_id: "复制设备 ID",
  add: "添加",
  update_device: "更新设备",
  select_type_of_device: "选择设备类型",
  select_model: "选择型号",
  enter_device_name: "输入设备名称",

  // Device Types
  GATEWAY: "网关",
  DOOR_CONTACT: "门触点",
  WATER_LEAK: "漏水检测仪",
  COOLING_SYSTEM: "冷却系统",
  UPS: "不间断电源",
  PDU: "配电单元",
  SMOKE_DETECTOR: "烟雾探测器",
  TEMP_HUMIDITY: "温度与湿度",
  IT_METER: "IT仪表",
  MAIN_METER: "主仪表",
  ACCESS_CONTROL: "门控制",
  EMERGENCY_FAN: "紧急风扇",
  ALARM_SOUND: "警报声",
  LED_STRIP: "LED灯带",
  IO_MODULE: "IO模块",
  env: "环境",

  // Device Details
  device: "设备",
  delete_device: "删除设备",
  device_id_copy_success: "已复制设备 ID",

  // menu tabs
  device_detail: "设备详情",
  set_parameter: "设置参数",

  // Device status
  value: "价值",
  input: "输入",

  // Front Door
  front_door: "前门",
  back_door: "后门",

  // Aircon
  serious_alarm: "严重警报启动",
  air_outlet_temperature: "出风口温度",
  AL49_fire_alarm: "AL49 火灾警报已激活",
  dc_fan_voltage: "DC 风扇电压",
  air_return_humidity: "回风湿度",
  running_frequency: "运行频率",
  AL50_high_air_return_temp: "AL50 回风温度过高",
  AL52_low_air_outlet_temp: "AL52 出风口温度过低",
  common_alarm: "公共警报激活",
  AL51_high_air_outlet_temp: "AL51 出风口温度过高",
  evaporation_temperature: "蒸发温度",
  suction_pressure: "吸气压力",
  target_frequency: "目标频率",
  discharge_pressure: "排气压力",
  "out-coil_temperature": "Out-Coil 温度",
  AL53_condensation_pump_fail: "AL53 冷凝泵故障",
  "in-coil_temperature": "In-Coil 温度",
  target_evaporation_temperature: "目标蒸发温度",
  discharge_temperature: "排气温度",
  air_return_temperature: "回风温度",
  "on/off": "开/关",
  communication_state: "通讯状态",
  temperature_setting: "温度设定",
  indoor_humidity: "室内湿度",
  humidity_setting: "湿度设定",
  compressor: "压缩机",
  "1#heater": "1# 加热器",
  "2#heater": "2# 加热器",
  indoor_temperature: "室内温度",
  unit_state: "机组运行状态",
  heater_temp_threshold: "加热器温度阈值",
  evaporator_temp: "蒸发器温度",
  condenser_protected_temp_threshold: "冷凝器保护温度阈值",
  cooling_start_temp: "冷却开始温度",
  condenser_temp: "冷凝器温度",
  evaporator_tube_temp: "蒸发管温度",
  cooling_threshold: "冷却阈值",
  high_temp_threshold: "高温阈值",
  heating_start_temp: "加热开始温度",
  heater: "加热器",
  debug_temp: "调试温度",
  low_temp_threshold: "低温阈值",
  evaporator_anti_frozen_temp_threshold: "蒸发器防冻温度阈值",
  return_temp: "返回温度",
  evaporator_anti_frozen_temp: "蒸发器防冻温度",
  condenser_protected_temp: "冷凝器保护温度",
  condenser_tube_temp: "冷凝管温度",
  air_suction_temperature: "吸气温度",
  liquid_pipe_temperature: "液管温度",
  subcooling: "过冷度",
  indoor_fan_speed: "室内风扇转速",
  compressor_output: "压缩机输出",
  outdoor_fan_speed: "室外风扇转速",
  superheat: "超热",
  // DTC
  working_status: "当前工作状态",
  air_supply_temperature: "送风温度",
  air_supply_temperature_setting: "送风温度设定",
  air_return_temperature_setting: "回风温度设定",
  air_return_humidity_setting: "回风湿度设定",
  comm_status: "通讯状态",
  unit_operating_status: "机组运行状态",
  air_supply_switch: "送风机开关",
  "1#_heating_switch": "1#电加热开关",
  "2#_heating_switch": "2#电加热开关",
  compressor_switch: "压缩机开关",
  condensate_pump_switch: "冷凝水泵开关",
  humidifying_switch: "加湿器开关",

  // Door
  door: "门",
  door_contact: "门触点",

  // Fire Alarm
  fire_alarm: "火警",

  // Meter
  pue: "电力使用效率",
  total_apparent_power: "总视在功率",
  total_active_power: "有功总功率",
  total_power_factor: "总功率因数",
  total_reactive_power: "无功总功率",
  average_phase_current: "平均相电流",
  frequency: "频率",
  average_phase_voltage: "平均相电压",
  import_active_energy: "输入有功电能",
  total_active_energy: "总有功能量",
  power: "功率",
  power_factor: "功率因数",

  // UPS
  battery_capacity: "电池容量",
  output_current: "输出电流",
  output_frequency: "输出频率",
  battery_voltage: "电池电压",
  output_power: "输出功率",
  battery_temperature: "电池温度",
  input_frequency: "输入频率",
  input_voltage: "输入电压",
  battery_timeRemaining: "电池剩余时间",
  output_load: "输出负载",
  input_current: "输入电流",
  output_voltage: "输出电压",
  max_temperature: "最高温度",
  ups_type: "不间断电源类型",
  battery_healthVoltage: "电池健康电压",
  input_truePower: "输入真实功率",
  output_a_phase_active_power: "输出A相有功功率",
  output_b_phase_active_power: "输出B相有功功率",
  output_c_phase_active_power: "输出C相有功功率",
  output_a_phase_load: "输出A相负载",
  output_b_phase_load: "输出B相负载",
  output_c_phase_load: "输出C相负载",
  output_a_phase_current: "输出A相电流",
  output_b_phase_current: "输出B相电流",
  output_c_phase_current: "输出C相电流",
  output_a_phase_voltage: "输出A相电压",
  output_b_phase_voltage: "输出B相电压",
  output_c_phase_voltage: "输出C相电压",
  output_a_phase_load_peak: "输出A相负载峰值",
  output_b_phase_load_peak: "输出B相负载峰值",
  output_c_phase_load_peak: "输出C相负载峰值",
  input_a_phase_current: "输入A相电流",
  input_b_phase_current: "输入B相电流",
  input_c_phase_current: "输入C相电流",
  input_a_phase_voltage: "输入A相电压",
  input_b_phase_voltage: "输入B相电压",
  input_c_phase_voltage: "输入C相电压",
  input_a_phase_power_factor: "输入A相功率因数",
  input_b_phase_power_factor: "输入B相功率因数",
  input_c_phase_power_factor: "输入C相功率因数",
  bypass_a_phase_voltage: "旁路A相电压",
  bypass_b_phase_voltage: "旁路B相电压",
  bypass_c_phase_voltage: "旁路C相电压",
  bypass_frequency: "旁路频率",
  buzzer: "警报器",
  load: "负载",
  utility_state: "实用状态",
  "i/p_fault_voltage": "I/P 故障电压",
  fault_state: "故障状态",
  battery_low_voltage: "电池电压低",
  shutdown_active: "关闭活动",
  battery_cell_voltage: "电池电压",
  // DTC
  input_voltage_a: "输入电压A",
  input_voltage_b: "输入电压B",
  input_voltage_c: "输入电压C",
  output_voltage_a: "输出电压A",
  output_voltage_b: "输出电压B",
  output_voltage_c: "输出电压C",
  output_current_a: "输出电流A",
  output_current_b: "输出电流B",
  output_current_c: "输出电流C",
  output_load_a: "输出负载A",
  output_load_b: "输出负载B",
  output_load_c: "输出负载C",
  standby_mode: "待机模式",
  bypass_mode: "旁路模式",
  online_mode: "在线模式",
  battery_mode: "电池模式",
  battery_test_mode: "电池测试模式",
  failure_mode: "故障模式",
  eco_mode: "省電模式",
  frequency_conversion_mode: "变频模式",
  shutdown_mode: "关机模式",

  // Smoke Sensor
  smoke_sensor: "烟雾传感器",
  smoke: "烟",
  // DTC
  smoke_detector: "烟雾探测器",

  // PDU
  l1_power_factor: "L1 相功率因数",
  l2_power_factor: "L2 相功率因数",
  l3_power_factor: "L3 相功率因数",
  temperature_1: "温度 1",
  temperature_2: "温度 2",
  temperature_3: "温度 3",
  l1_current: "L1 电流",
  l2_current: "L2 电流",
  l3_current: "L3 电流",
  l1_energy: "L1 能量",
  l2_energy: "L2 能量",
  l3_energy: "L3 能量",
  l1_voltage: "L1 电压",
  l2_voltage: "L2 电压",
  l3_voltage: "L3 电压",
  humidity_1: "湿度 1",
  humidity_2: "湿度 2",
  l1_power: "L1 功率",
  l2_power: "L2 功率",
  l3_power: "L3 功率",
  total_energy: "总电能",
  "1#current": "1# 电流",
  "2#current": "2# 电流",
  "1#voltage": "1# 电压",
  "2#voltage": "2# 电压",
  "1#energy": "1# 能量",
  "2#energy": "2# 能量",
  "1#temperature": "1# 温度",
  "2#temperature": "2# 温度",
  "1#humidity": "1# 湿度",
  "2#humidity": "2# 湿度",
  current: "电流",
  voltage: "电压",
  energy: "能量",
  // DTC
  power_frequency: "电源频率",
  phase_a_power: "A相功率",
  phase_b_power: "B相功率",
  phase_c_power: "C相功率",
  phase_a_current: "A相电流",
  phase_b_current: "B相电流",
  phase_c_current: "C相电流",

  // Bluenet PDU
  input_phase1_activePower: "输入第1相有功功率",
  input_phase2_activePower: "输入第2相有功功率",
  input_phase3_activePower: "输入第3相有功功率",
  input_phase1_current: "输入第1相电流",
  input_phase2_current: "输入第2相电流",
  input_phase3_current: "输入第3相电流",
  input_phase1_voltage: "输入第1相电压",
  input_phase2_voltage: "输入第2相电压",
  input_phase3_voltage: "输入第3相电压",
  output_outlet1_activePower: "输出插座1有功功率",
  output_outlet2_activePower: "输出插座2有功功率",
  output_outlet3_activePower: "输出插座3有功功率",
  output_outlet4_activePower: "输出插座4有功功率",
  output_outlet1_current: "输出插座1电流",
  output_outlet2_current: "输出插座2电流",
  output_outlet3_current: "输出插座3电流",
  output_outlet4_current: "输出插座4电流",
  output_outlet1_voltage: "输出插座1电压",
  output_outlet2_voltage: "输出插座2电压",
  output_outlet3_voltage: "输出插座3电压",
  output_outlet4_voltage: "输出插座4电压",

  // Temp & Humidity
  emergency_fan_start_temp: "紧急风扇启动温度",
  emergency_fan_stop_temp: "紧急风扇停止温度",
  low_humidity_threshold: "低湿度阈值",
  high_humidity_threshold: "高湿度阈值",
  low_temperature_threshold: "低温阈值",
  high_temperature_threshold: "高温阈值",

  // Water Leakage
  water_leakage: "漏水状态",

  // BLE
  battery: "电池",

  // Values
  open: "开",
  close: "关",
  normal: "正常",
  alarm: "警报",
  on: "开",
  off: "关",
  stop: "停止",
  cooling_and_dehumidification: "制冷除湿",
  cooling_and_humidification: "制冷加湿",
  heating_and_dehumidification: "制热除湿",
  heating_and_humidification: "制热加湿",
  cooling: "制冷",
  heating: "制热",
  humidification: "加湿",
  dehumidification: "除湿",
  air_supply: "送风",
  online: "在线",
  offline: "离线",
  ok: "正常",
  low: "电量不足",
  true: "是",
  false: "不",

  // others
  excel_filename_devices: "设备报告",
};

export default messages;
