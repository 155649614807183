const messages = {
  home_widget_btn: "添加小部件",

  /** grid widgets */
  home_widget_online_devices: "在线设备",
  home_widget_smoke_sensor: "烟雾传感器",
  home_widget_door_alarm: "门警报",
  home_widget_pdu_alert: "PDU警报",
  home_widget_critical_alarm: "紧急警报",
  home_widget_water_leak: "漏水",

  home_week_filter: "星期",
  home_day_filter: "天",
  home_hour_filter: "小时",
  home_all_sites: "所有网站",
  /** Widgets */
  home_widget_humidity: "湿度",
  home_widget_temperature: "温度",
  home_widget_legend_week: "星期",
  home_widget_legend_period: "前一时期",
  home_widget_del_text: "删除该小部件将影响所有其他用户。您确定要删除吗？",
  home_widget_del_dialog: "删除小部件",
  home_widget_del_btn: "删除",
  home_widget_cancel: "取消",

  home_widget_main_meter: "主仪表",
  home_widget_it_meter: "信息技术仪表",
  home_widget_pue: "PUE",
  home_widget_temp_humidity: "温度和湿度",
  home_widget_ups_alarm: "UPS设备报警",
  home_widget_cooling_system_alarm: "冷却装置警报",

  home_widget_table_cooling: "冷却装置",
  home_widget_table_ups: "UPS 设备",
  home_widget_site_col: "地点",
  home_widget_device_col: "设备",
  home_widget_alert_col: "警报",
  home_widget_severity_col: "严重程度",
  home_widget_date_col: "日期",

  home_map_title: "地理地图",
};

export default messages;
