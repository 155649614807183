import { createContext } from "react";

export const TenantIdContext = createContext({});

export const TenantIdProvider = (props: any) => {
  const { children, tenantId, isMasqAsSubTenant } = props;

  return (
    <TenantIdContext.Provider
      value={{
        tenantId: tenantId,
        isMasqAsSubTenant: isMasqAsSubTenant,
      }}
    >
      {children}
    </TenantIdContext.Provider>
  );
};
