const messages = {
  home_widget_btn: "Add Widget",

  /** grid widgets */
  home_widget_online_devices: "Online Devices",
  home_widget_smoke_sensor: "Smoke Sensor",
  home_widget_door_alarm: "Door Alarm",
  home_widget_pdu_alert: "PDU Alert",
  home_widget_critical_alarm: "Critical Alarm",
  home_widget_water_leak: "Water Leak",

  home_week_filter: "Week",
  home_day_filter: "Day",
  home_hour_filter: "Hour",
  home_all_sites: "All Site",
  /** Widgets */
  home_widget_humidity: "Humidity",
  home_widget_temperature: "Temperature",
  home_widget_legend_week: "Week",
  home_widget_legend_period: "Preceding Period",
  home_widget_del_text:
    "Deleting widgets will affect all other users. Are you sure want to delete?",
  home_widget_del_dialog: "Delete Widget",
  home_widget_del_btn: "Delete",
  home_widget_cancel: "Cancel",
  home_widget_main_meter: "Main Meter",
  home_widget_it_meter: "IT Meter",
  home_widget_pue: "PUE",
  home_widget_temp_humidity: "Temperature & Humidity",
  home_widget_ups_alarm: "UPS Device Alarm",
  home_widget_cooling_system_alarm: "Cooling Units Alarm",

  home_widget_table_cooling: "Cooling Units",
  home_widget_table_ups: "UPS Devices",
  home_widget_site_col: "Site",
  home_widget_device_col: "Device",
  home_widget_alert_col: "Alert",
  home_widget_severity_col: "Severity",
  home_widget_date_col: "Date",

  home_map_title: "Geographic Map",
};

export default messages;
