const messages = {
  home_widget_btn: "Ajouter un widget",

  /** grid widgets */
  home_widget_online_devices: "Appareils en ligne",
  home_widget_smoke_sensor: "Détecteur de fumée",
  home_widget_door_alarm: "Alarme de porte",
  home_widget_pdu_alert: "Alerte PDU",
  home_widget_critical_alarm: "Alarme critique",
  home_widget_water_leak: "Fuite d'eau",

  home_week_filter: "Semaine",
  home_day_filter: "Jour",
  home_hour_filter: "Heure",
  home_all_sites: "Tous les sites",
  /** Widgets */
  home_widget_humidity: "Humidité",
  home_widget_temperature: "Température",
  home_widget_legend_week: "Semaine",
  home_widget_legend_period: "Période précédente",
  home_widget_del_text:
    "La suppression des widgets affectera tous les autres utilisateurs. Êtes-vous sûr de vouloir les supprimer ?",
  home_widget_del_dialog: "Supprimer le widget",
  home_widget_del_btn: "Supprimer",
  home_widget_cancel: "Annuler",

  home_widget_main_meter: "Compteur principal",
  home_widget_it_meter: "Compteur informatique",
  home_widget_pue: "PUE",
  home_widget_temp_humidity: "Température et humidité",
  home_widget_ups_alarm: "Alarme de l'appareil UPS",
  home_widget_cooling_system_alarm: "Alarme des unités de refroidissement",

  home_widget_table_cooling: "Unités de refroidissement",
  home_widget_table_ups: "Dispositifs UPS",
  home_widget_site_col: "Site",
  home_widget_device_col: "Appareil",
  home_widget_alert_col: "Alerte",
  home_widget_severity_col: "Gravité",
  home_widget_date_col: "Date",

  home_map_title: "Carte géographique",
};

export default messages;
