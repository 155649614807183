const messages = {
  attachment: "Attachment",
  attachments: "Attachments",
  upload_btn: "Upload",
  cancel_btn: "Cancel",
  reset_btn: "Reset",
  apply_btn: "Apply",
  delete_btn: "Delete",
  location: "Location",
  coordinates: "Coordinates",
  format: "Format",
  date: "Date",
  site: "Site",
  details: "Details",
  download: "Download",
  status: "Status",
  alert: "Alert",
  delete: "Delete",
  new_btn: "Create New",
  delete_location_btn: "Delete Location",
  save_changes: "Save Changes",
  home: "Home",
  site_placeholder: "Select Site",
  site_validation_msg: "Please select site.",
  name: "Name",
  name_placeholder: "Enter Name",
  name_validation_msg: "Please enter name.",
  location_label: "Location Name",
  location_placeholder: "Enter Location Name",
  location_validation_msg: "Please enter location name.",
  country: "Country",
  country_placeholder: "Enter Country",
  country_validation_msg: "Please select country.",
  longitude: "Longitude",
  longitude_placeholder: "Enter Longitude",
  longitude_validation_msg: "Please select longitude.",
  latitude: "Latitude",
  latitude_placeholder: "Enter Latitude",
  latitude_validation_msg: "Please select latitude.",
  description: "Description",
  description_placeholder: "Enter Description",
  description_validation_msg: "Please enter description.",
  add_site_label: "Site Name",
  add_site_placeholder: "Enter Site Name",
  add_site_validation_msg: "Please enter site.",
  url: "URL",
  url_placeholder: "Enter URL",
  url_validation_msg: "Please enter url.",
  file_placeholder: "Select File Format",
  attachment_upload_msg: "Attachment uploaded successfully",
  attachment_upload_error: "Failed to upload attachment.",
  drag_drop_label: "Drag and Drop to upload or",
  choose_file: "Choose File",
  maximun_file_size: "Maximum file size 100MB",
  invalid_file_format: "File format not supported.",
  file_size_validation: "File must be smaller than 100MB!",
  file_required: "Please upload a file!",
  delete_attachment_title: "Delete Attachment",
  delete_attachment_text: "Are you sure you want to delete?",
  delete_location_text:
    "Are you sure you want to delete this location? This action cannot be undone.",
  information_modal_title: "Attachment Information",
  location_delete_validation:
    "Unable to delete. one or more active site exist in this location",
  add_location: "Add Location",
  add_location_msg: "Location Added Successfully",
  update_location_msg: "Location Updated Successfully",
  del_site_validation_msg:
    "Unable to delete. one or more devices exist in this site",
  add_site: "Add New Site",
  attachment_deleted_success: "Attachment deleted successfully",
};

export default messages;
